<script setup>
import { Link } from "@inertiajs/vue3";
import { useAppStore } from '@/stores/AppStore';
import useCrumbs from "../composables/useCrumbs";

import { HomeIcon } from "@heroicons/vue/20/solid";
import ChevronRightIcon from "./Icons/ChevronRightIcon.vue";



const props = defineProps({
    separator: {
        type: Object,
        default: ChevronRightIcon
    },
    handleSubTab: {
        type: Boolean,
        default: false
    }
});

const app = useAppStore()
const { crumbs, isArrOfObjects } = useCrumbs()

const handleClick = (crumb) => {
    if (crumb.service) {
        app.activeTab = crumb.service
    }
}
</script>
<template>
    <nav class="flex items-center mx-4 md:mx-0" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-2 overflow-x-scroll">
            <li>
                <slot name="firstDefaultItem">
                    <div>
                        <Link href="/" class="text-gray-400 hover:text-gray-500">
                        <HomeIcon class=" w-5 h-5" />
                        <span class="sr-only">Home</span>
                        </Link>
                    </div>
                </slot>
            </li>

            <template v-if="isArrOfObjects">
                <li v-for="(crumb, i) in crumbs" :key="i">
                    <div class="flex items-center justify-center text-gray-400 hover:text-gray-500 transition-all duration-200">
                        <Component :is="props.separator" v-if="props.separator" class="w-4 h-4" />
                        <ChevronRightIcon v-else class="h-4 w-4" />

                        <Component :is="crumb.icon" v-if="crumb.icon" class="w-4 h-4" />
                        <Link :href="crumb.link" as="button" class="ml-2 text-gray-500 hover:text-gray-700 hidden md:inline whitespace-nowrap" @click="handleClick(crumb)">{{ crumb.title }}</Link>
                        <Link :href="crumb.link" as="button" class="md:hidden ml-2 text-gray-500 hover:text-gray-700 whitespace-nowrap" @click="handleClick(crumb)">{{ crumb.title }}</Link>
                    </div>
                </li>
            </template>
            <template v-else>
                <li v-for="(crumb, i) in crumbs" :key="i">
                    <div class="flex items-center">
                        <ChevronRightIcon class="h-4" />
                        <Link v-if="i === 0" :href="`/${crumb}`" class="ml-2 text-gray-500 hover:text-gray-700">{{ crumb }}
                        </Link>
                        <Link v-else :href="`${crumb}`" class="ml-2 text-gray-500 hover:text-gray-700">{{ crumb }}
                        </Link>
                    </div>
                </li>
            </template>
        </ol>
    </nav>
</template>
