<script setup>
import NavLink from '@/Components/Buttons/NavLink.vue';
import NavLinkDropdown from '@/Components/Buttons/NavLinkDropdown.vue';
import { Link, router } from '@inertiajs/vue3';
import { useAppStore } from '@/stores/AppStore';

const app = useAppStore();

defineProps({
    navItems: {
        type: Array,
        default() {
            return [];
        }
    }
});
</script>

<template>
<nav class="hidden lg:mb-2 lg:pb-8 lg:pt-4 lg:block">
    <ul
        v-if="app.getNavbarCollapseState"
        class="py-2 pr-4"
    >
        <li
            v-for="item in navItems"
            :key="item.title"
        >
            <Link
                v-if="item.display"
                :class="[
                    $page.url.startsWith(item.urlSlug) ||
                        item.children.some((child) =>
                            $page.url.endsWith(child.link?.toLowerCase())
                        )
                        ? 'text-holbrook-yellow border-l-2 border-holbrook-yellow'
                        : 'text-white'
                ]"
                :href="
                    item.children.length ? item.children[0].link : item.link
                "
                :title="item.title"
                class="flex items-center h-12 pl-6 max-h-12 min-h-12 rounded rounded-l-none hover:border-l-2 hover:border-holbrook-yellow hover:bg-holbrook-hover hover:text-holbrook-yellow"
            >
                <Component
                    :is="item.icon"
                    class="h-6 w-6"
                />
            </Link>
        </li>
    </ul>

    <ul
        v-else
        class="text-sm font-medium"
        role="list"
    >
        <template
            v-for="item in navItems"
            :key="item.title"
        >
            <li v-if="item.display">
                <NavLink
                    v-if="!item.children.length > 0"
                    :href="item.link"
                    :slug="item.urlSlug"
                    :title="item.title"
                >
                    <Component
                        :is="item.icon"
                        class="h-6 w-6"
                    />
                </NavLink>
                <NavLinkDropdown
                    v-else
                    :display="item?.display"
                    :href="item.link"
                    :items="item.children"
                    :slug="item.urlSlug"
                    :title="item.title"
                >
                    <Component
                        :is="item.icon"
                        class="h-6 w-6"
                    />
                </NavLinkDropdown>
            </li>
        </template>
        <li class="mt-8 w-full justify-center flex">
            <button
                class="px-6 py-2 font-semibold rounded bg-holbrook-yellow hover:bg-ha-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ha-500"
                @click="router.post('/logout')"
            >
                Log Out
            </button>
        </li>
    </ul>
</nav>
</template>
