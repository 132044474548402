<script setup>
import { onMounted, ref } from "vue";
import { useClockStore } from "@/stores/ClockStore";
import axios from "axios";
import ClockOut from "@/Components/Clock/Modules/ClockOut.vue";

const clock = useClockStore();

const fleets = ref();
const cities = ref();

function getFleetData() {
    return axios.get("/api/vehicles");
}

function getCitiesData() {
    return axios.get("/api/cities");
}

onMounted(() => {
    if (clock.timeClock && clock.timeClock.trip) {
        clock.mob.to = clock.timeClock.trip.start_location_id;
        clock.mob.from = clock.timeClock.trip.end_location_id;
        clock.mob.fleet_id = clock.timeClock.trip.fleet_id;
        clock.mob.method = clock.timeClock.trip.flight
            ? "flight"
            : clock.timeClock.trip.driver
            ? "driver"
            : "passenger";
    }
    axios.all([getFleetData(), getCitiesData()]).then(
        axios.spread(function (fleet, city) {
            fleets.value = fleet.data;
            cities.value = city.data;
        })
    );
});
</script>

<template>
<div>
    <div class="flex flex-col space-y-4 mx-6">
        <div class="flex flex-col text-left">
            <label class="text-sm" for="mobFromCity">From</label>
            <select
                id="mobFromCity"
                v-model="clock.mob.from"
                class="rounded-sm bg-gray-select border border-gray-select-bg font-normal"
            >
                <option selected value="">Choose City</option>
                <option
                    v-for="(city, index) in cities"
                    :key="`to-${city.id}`"
                    :value="city.id"
                >
                    {{ city.display_name }} {{ `(${city.region.state})` }}
                </option>
            </select>
            <!--                <x-inputs.input-error for="mob.from"></x-inputs.input-error>-->
        </div>
        <div class="flex flex-col text-left">
            <label class="text-sm" for="mobToCity">To</label>
            <select
                id="mobToCity"
                v-model="clock.mob.to"
                class="rounded-sm bg-gray-select border border-gray-select-bg font-normal"
            >
                <option selected value="">Choose City</option>
                <option
                    v-for="(city, index) in cities"
                    :key="`from-${city.id}`"
                    :value="city.id"
                >
                    {{ city.display_name }} {{ `(${city.region.state})` }}
                </option>
            </select>
            <!--                <x-inputs.input-error for="mob.to"></x-inputs.input-error>-->
        </div>
        <div class="flex flex-col text-left">
            <label class="text-sm" for="mobMethod">Method</label>
            <select
                id="mobMethod"
                v-model="clock.mob.method"
                class="rounded-sm bg-gray-select border border-gray-select-bg font-normal"
            >
                <option selected value="">Choose Method</option>
                <option value="driver">Driver</option>
                <option value="flight">Flight</option>
                <option value="passenger">Passenger</option>
            </select>
            <!--                <x-inputs.input-error for="mob.method"></x-inputs.input-error>-->
        </div>
        <div v-if="clock.mob.method === 'driver'" class="text-left">
            <label for="mobFleet">Fleet</label>
            <select id="fleet" v-model="clock.mob.fleet_id">
                <option
                    v-for="fleet in fleets"
                    :key="fleet.id"
                    :value="fleet.id"
                >
                    {{ `${fleet.fleet_number} ${fleet.name}` }}
                </option>
            </select>
            <!--                <x-inputs.input-error for="mob.fleet_id"></x-inputs.input-error>-->
        </div>
    </div>
    <template
        v-if="
            clock.clockDirection === 'Out' ||
                clock.clockDirection === 'Switch'
        "
    >
        <ClockOut />
    </template>
    <div
        v-else
        class="mt-5 mx-6 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3"
    >
        <button
            :disabled="clock.mob.method === 'driver' && !clock.mob.fleet_id"
            class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-1"
            type="button"
            @click="clock.saveTimeEntry('mob')"
        >
            MOB Order Clock
            {{ clock.clockDirection }}
        </button>
    </div>
</div>
</template>

<style lang="scss" scoped></style>
